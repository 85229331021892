<template>
    <v-container class="mt-8 mb-12">
        <v-row align="center" justify="center" v-if="!localStream">
            <v-col class="col-12" sm="6" md="6">
                <v-card class="elevation-1 rounded-l">
                    <v-card-text>
                        Welcome to my Channel

                        <div class="text-center mb-50">
                            <img height="260px" src="../../assets/channel_join.png">
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-container>
                            <v-row justify="center" align="center">
                                <v-col xs="12" sm="12" md="12" class="col-12">
                                    <v-btn
                                        block
                                        color="secondary"
                                        class="text-white border-12"
                                        @click="joinEvent" 
                                        :disabled="disableJoin"
                                    >
                                    Join Channel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" v-if="localStream">
            <v-col class="col-12" sm="10" md="10">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <div class="agora-view">
                                    <div class="agora-video">
                                        <StreamPlayer :stream="localStream" :domId="localStream.getId()" v-if="localStream"></StreamPlayer>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="8">
                                <div class="text-center" style="height: auto;width: 100%;position: absolute;left: 40px;bottom: 15%;">
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="grey"
                                        @click="setVideo()"
                                    >
                                        <v-icon dark v-if="video">
                                            mdi-pause
                                        </v-icon>
                                        <v-icon dark v-else>
                                            mdi-play
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        large
                                        color="error"
                                        @click="[dialog = false, leaveEvent()]"
                                        :disabled="!disableJoin"
                                        ripple
                                    >
                                        <v-icon dark>
                                            mdi-phone-hangup
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        small
                                        color="grey"
                                        @click="setMute()"
                                    >
                                        <v-icon dark v-if="mute">
                                            mdi-microphone
                                        </v-icon>
                                        <v-icon dark v-else>
                                            mdi-microphone-off
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <div v-if="remoteStreams.length > 0">
                                <div style="height: 300px;width: 250px;position: absolute;left: 40px;bottom: 15%;" 
                                    :key="index" v-for="(remoteStream, index) in remoteStreams">
                                    <StreamPlayer :stream="remoteStream" :domId="remoteStream.getId()"></StreamPlayer>
                                </div>
                            </div>
                            <div style="height: 300px;width: 220px;position: absolute;left: 40px;bottom: 5%;"
                            v-else>
                                <v-img src="../../assets/default-user.png" alt="Admin"/>
                            </div>
                            
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import RTCClient from "../../stream/agora";
import StreamPlayer from "../../components/StreamPlayer.vue";
import { log } from '../../stream/utils/utils'
import {generateToken} from '../../stream/generateToken'
import {backendAdmin} from '../../backend-api/backendAdmin'
  
export default {
    components: {
        StreamPlayer
    },
    data () {
        return {
            option: {
                appid: process.env.VUE_APP_ID,
                token: '',
                uid: null,
                channel: process.env.VUE_CHANNEL_NAME,
                app_sertificate: process.env.VUE_APP_SERTIFICATE,
                localAudioTrack: null,
                localVideoTrack: null
            },
            disableJoin: false,
            localStream: null,
            remoteStreams: [],
            mute: true,
            video: true,
            localTracks: {
                audioTrack: null
            }
        }
    },
    methods: {
        async joinEvent () { 

            if(!this.option.appid) {
                console.log('Appid');
                return
            }
            if(!this.option.channel) {
                console.log('Channel Name');
                return
            }
            this.rtc.joinChannel(this.option).then(() => {
                console.log('Join Success');
                // this.$message({
                //     message: 'Join Success',
                //     type: 'success'
                // });
                this.rtc.publishStream().then((stream) => {
                    console.log('Publish Success');
                    // this.$message({
                    // message: 'Publish Success',
                    // type: 'success'
                    // });
                    this.localStream = stream
                    console.log(this.localStream);
                }).catch((err) => {
                    console.log(err);
                    // this.$message.error('Publish Failure');
                    // log('publish local error', err)
                })
            }).catch((err) => {
                console.log(err);
                if (err === 'DYNAMIC_KEY_EXPIRED') {
                    this.tokenExpired()
                }
                // this.$message.error('Join Failure');
                // log('join channel error', err)
            })
            this.disableJoin = true
        },
        leaveEvent () {
            this.disableJoin = false
            this.rtc.leaveChannel().then(() => {
                console.log('Leave Success');
                // this.$message({
                //     message: 'Leave Success',
                //     type: 'success'
                // });
            }).catch((err) => {
                console.log('Leave Failure');
                // this.$message.error('Leave Failure')
                // log('leave error', err)
            })
            this.localStream = null
            this.remoteStreams = []
        },
        async getToken(){
            var reqBody = {
                'id': parseInt(this.$route.params.id)
            }
            const respData = await backendAdmin.fetchCore('/api/v1/get_video_token', reqBody, false, false, false)

            return respData.data.value
        },
        async setToken(token){
            var reqBody = {
                'id': parseInt(this.$route.params.id),
                'token': token
            }
            const respData = await backendAdmin.fetchCore('/api/v1/set_video_token', reqBody, false, false, false)

        },
        async setMute(){
            if (this.mute === true) {
                this.localStream.muteAudio()
            } else {
                this.localStream.unmuteAudio()
            }
            
            this.mute = this.mute === true ? false : true

            this.localStream.audio = this.mute

            console.log(this.localStream);

            this.rtc.unpublishChannel().then(() => {
                console.log('unpublish Success');
            })

            this.rtc.publishChannel(this.localStream).then((stream) => {
                console.log('Publish Success');
                // this.$message({
                // message: 'Publish Success',
                // type: 'success'
                // });
                this.localStream = stream
                console.log(this.localStream);
            }).catch((err) => {
                console.log(err);
                // this.$message.error('Publish Failure');
                // log('publish local error', err)
            })

            // console.log(this.localStream);
        },
        async setVideo(){
            this.video = this.video === true ? false : true
        },
        async tokenExpired(){
            const resP = await generateToken.token()
            this.setToken(resP)
            this.option.token = resP

            this.rtc = new RTCClient(resP)
            let rtc = this.rtc
            rtc.on('stream-added', (evt) => {
                let {stream} = evt
                log("[agora] [stream-added] stream-added", stream.getId())
                rtc.client.subscribe(stream)
            })
                
            rtc.on('stream-subscribed', (evt) => {
                let {stream} = evt
                log("[agora] [stream-subscribed] stream-added", stream.getId())
                if (!this.remoteStreams.find((it) => it.getId() === stream.getId())) {
                    if (this.remoteStreams.length > 1) {
                        // roomFull();
                        log("[agora] full rooms", stream.getId())
                    } else {
                        this.remoteStreams.push(stream)
                    }
                }
            })

            rtc.on('stream-removed', (evt) => {
                let {stream} = evt
                log('[agora] [stream-removed] stream-removed', stream.getId())
                this.remoteStreams = this.remoteStreams.filter((it) => it.getId() !== stream.getId())
            }) 

            rtc.on('peer-online', (evt) => {
                this.$message(`Peer ${evt.uid} is online`)
            }) 

            rtc.on('peer-leave', (evt) => {
                this.$message(`Peer ${evt.uid} already leave`)
                this.remoteStreams = this.remoteStreams.filter((it) => it.getId() !== evt.uid)
            }) 

            this.joinEvent()
        },
    },
    async created() {
        var token = await this.getToken()

        if (token.token) {
            var TokenNew = token.token
        } else {
            const resP = await generateToken.token()
            this.setToken(resP)
            var TokenNew = resP
        } 

        this.option.token = TokenNew

        console.log(TokenNew);
        
        this.rtc = new RTCClient(TokenNew)
        let rtc = this.rtc
        rtc.on('stream-added', (evt) => {
            let {stream} = evt
            log("[agora] [stream-added] stream-added", stream.getId())
            rtc.client.subscribe(stream)
        })
            
        rtc.on('stream-subscribed', (evt) => {
            let {stream} = evt
            log("[agora] [stream-subscribed] stream-added", stream.getId())
            if (!this.remoteStreams.find((it) => it.getId() === stream.getId())) {
                if (this.remoteStreams.length > 1) {
                    // roomFull();
                    log("[agora] full rooms", stream.getId())
                } else {
                    this.remoteStreams.push(stream)
                }
            }
        })

        rtc.on('stream-removed', (evt) => {
            let {stream} = evt
            log('[agora] [stream-removed] stream-removed', stream.getId())
            this.remoteStreams = this.remoteStreams.filter((it) => it.getId() !== stream.getId())
        }) 

        rtc.on('peer-online', (evt) => {
            this.$message(`Peer ${evt.uid} is online`)
        }) 

        rtc.on('peer-leave', (evt) => {
            this.$message(`Peer ${evt.uid} already leave`)
            this.remoteStreams = this.remoteStreams.filter((it) => it.getId() !== evt.uid)
        }) 

        this.joinEvent()

    },
    mounted() {
    },
}
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.agora-box {

}
.agora-title {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #2c3e50;
}
.agora-view {
    display: flex;
    flex-wrap: wrap;
}
.agora-video {
    width: 100%;
    height: 600px;
    margin: 20px;
}

.agora-video-list{
    width: 320px;
    height: 180px;
    margin: 20px;
}
.agora-input {
    margin: 20px;
    width: 320px;
}
.agora-text {
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
}
.agora-button {
    display: flex;
    width: 160px;
    justify-content: space-between;
    margin: 20px;
}
</style>