import Vue from "vue";
import axios from 'axios';
import store from '../store'
import router from '../router'

export const backendAdmin = (() =>{
    const test = (() => {
        return ""
    })

    const fetchCore = async (subPath, reqBody, outputObj = false, outputJson = false, cancelToken=false, token=true) => {
        if (reqBody && token === true) {
            try {
                const response = await axios.post(process.env.VUE_APP_URL + subPath, reqBody, { 
                    headers: { Authorization: `Bearer ${store.getters.isTokenAdmin}` } 
                })
                return response;
                // Work with the response...
            } catch (err) {
                // Handle error
                if (err.response.status === 401) {
                    router.push('/')
                }
                return err.response;
            }
        } else if (token === true) {
            try {
                const response = await axios.get(process.env.VUE_APP_URL + subPath, { 
                    headers: { Authorization: `Bearer ${store.getters.isTokenAdmin}` } 
                })
                return response;
            } catch (err) {
                // Handle error
                if (err.response.status === 401) {
                    router.push('/')
                }
                return err.response;
            }
        } else {
            if (reqBody){
                try {
                    const response = await axios.post(process.env.VUE_APP_URL + subPath, reqBody)
        
                    return response;
                    // Work with the response...
                } catch (err) {
                    return err.response;
                }
            } else{
                try {
                    const response = await axios.get(process.env.VUE_APP_URL + subPath)
                    return response;
                } catch (err) {
                    return err.response;
                }
            }
        }
    }

    const updateCore = async(subPath, reqBody) =>{
        try {
            const response = await axios.put(process.env.VUE_APP_URL + subPath, reqBody, { 
                headers: { Authorization: `Bearer ${store.getters.isTokenAdmin}` } 
            })
            return response;
        } catch (err) {
            if (err.response.status === 401) {
                router.push('/')
            }
            return err.response;
        }
    }

    return {fetchCore, updateCore, test};
})()